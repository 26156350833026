// eslint-disable-next-line import/prefer-default-export
export const assetStates = {
  IN: {
    code: 0,
    label: 'In',
    icon: 'LBoxSentIcon',
    style: 'color:green',
  },
  PICKED: {
    code: 1,
    label: 'Picked',
    icon: 'LPickIcon',
  },
  PACKED: {
    code: 2,
    label: 'Packed',
    icon: 'LBoxIcon',
  },
  OUT: {
    code: 3,
    label: 'Out/dispatched',
    icon: 'LTruckIcon',
  },
  RECEIVED: {
    code: 4,
    label: 'Received',
    icon: 'LWarehouseIcon',
  },
  MISSING: {
    code: 5,
    label: 'Missing',
  },
  DAMAGED: {
    code: 6,
    label: 'Damaged',
    icon: 'LRepairIcon',
    style: 'fill: #BB2124 !important',
  },
  SERVICE: {
    code: 7,
    label: 'Service',
    icon: 'LRepairIcon',
    style: 'fill: #BB2124 !important',
  },
  TRANSFER: {
    code: 8,
    label: 'Transfer',
    icon: 'LTransferCalendarIcon',
    style: 'fill:green !important',
  },
  CONFLICT_A: {
    code: 9,
    label: 'Conflict A',
    icon: 'LWarningIcon',
  },
  CONFLICT_B: {
    code: 10,
    label: 'Conflict B',
    icon: 'LNoIcon',
    style: 'fill: #BB2124 !important',
  },
  RESERVED: {
    code: 11,
    label: 'Reserved',
    icon: 'LHoldIcon',
    style: 'fill:green !important',
  },
}
