<template>
  <div>
    <b-modal
      :id="MODAL_ID"
      centered
      hide-header
      :body-class="`${MODAL_ID}-body py-2`"
      :footer-class="`${MODAL_ID}-footer`"
      ok-variant="danger"
      ok-title="Report"
      :modal-class="`${MODAL_ID}`"
      cancel-variant="outline-primary"
      cancel-title="Cancel"
      size="lg"
      @hide="hide"
    >
      <template #modal-footer>
        {{ '' }}
      </template>
      <h4 class="font-weight-bolder text-center">
        <span>
          {{ MODAL_TITLE_PREFIX }}
          {{ product.sku }}
        </span>
        —
        <span>
          {{ product.name }}}}
        </span>
      </h4>
      <change-asset-to-damaged-form
        @onModalHide="onModalHide"
      />
    </b-modal>
  </div>
</template>

<script>
import ChangeAssetToDamagedForm from './components/Form.vue'
import config from './config'

export default {
  name: 'ChangeAssetToDamaged',
  components: {
    ChangeAssetToDamagedForm,
  },
  computed: {
    product() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  methods: {
    hide() { this.$bvModal.hide(this.MODAL_ID) },
    show() { this.$bvModal.show(this.MODAL_ID) },
    onModalHide() {
      this.hide()
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { MODAL_ID, MODAL_TITLE_PREFIX } = config()

    return {
      MODAL_ID,
      MODULE_NAME,
      MODAL_TITLE_PREFIX,
    }
  },
}
</script>
<style lang="scss">
  .modal-backdrop {
    background: rgb(12 12 12 / 14%);
  }
.change-asset-to-damaged-modal {
.modal-content {
  box-shadow: none !important;
}
  &-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: none;
    padding: 0 !important;
  }

}
</style>
